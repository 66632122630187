import React, { useEffect,useRef, useState } from 'react'
import Footer from "../../Components/Footer";
import {useParams} from "react-router-dom";
import PagesHeader from '../../Components/PagesHeader';
import moment from 'moment';
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();
function BlogDetailPage() { 
    const didMountRef = useRef(true);
    const { slug } = useParams();
    const [pageData , setPageData] = useState({})
    const [headerImage , setHeaderImage] = useState("")
    const [blogDetailData , setBlogDetailData] = useState({})
    const [blogDetailImageUrl , setBlogDetailImageUrl] = useState("")
    const [defaultImageUrl , setDefaultImageUrl] = useState("")
    const [recentPostData , setRecentPostData] = useState([])
    const [recentPostImageUrl , setRecentPostImageUrl] = useState("")
    const [categoryData , setCategoryData] = useState([])
    useEffect(() => {
        if (didMountRef.current) { 
       const dataString = {
      "page_url" : "postpage"
       }
       apiServices.pagePostRequest(dataString).then(res => {
        if(res.data.status === "success"){
            if(res.data.pageData != null){
          setPageData(res.data.pageData)
          setHeaderImage(res.data.page_header_url) 

            }
       }
    })
    const dataString2 = {
        "blog_slug" : slug
    }
       apiServices.blogdetailsPostRequest(dataString2).then(res => {
        if(res.data.status === "success"){
            setBlogDetailData(res.data.blogDetails)
            setBlogDetailImageUrl(res.data.blog_url)
            setDefaultImageUrl(res.data.default_image_baseurl)
            setCategoryData(res.data.categoryData)
        }
       })
       apiServices.recentblogGetRequest().then(res=>{
        if(res.data.status == "success"){
            setRecentPostData(res.data.blogsData)  
            setRecentPostImageUrl(res.data.blog_url)
            setDefaultImageUrl(res.data.default_image_baseurl)
        }
    })
      
    }
  didMountRef.current = false;
  }); 
    return (
      <>

<PagesHeader/>
<div id="cappa-main">
      <div className="banner-header section-padding valign bg-img bg-fixed" data-overlay-dark="4" style={{backgroundImage: `url(${blogDetailData.blog_thumbnail_image!= null ?blogDetailImageUrl+blogDetailData.blog_thumbnail_image:""})`,backgroundSize:'cover',backgroundPosition:'100%'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center caption mt-90">
                    <h5><a href="/blogs">Blogs</a></h5>
                    <h1>{blogDetailData.blog_name}</h1>
                    <div className="post">
                       
                        <div className="date-comment"> <i className="ti-calendar"></i> {moment(blogDetailData.created_at).format('DD-MMM-YYYY')}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section className="section-padding">
        <div className="container">
            <div className="row">
                <div className="col-md-8"> 
                    <img src={blogDetailData.blog_image!=null ?blogDetailImageUrl + blogDetailData.blog_image:"/assets/img/defaultimage.png"} className="mb-30" alt=""/>
                    <h2>{blogDetailData.blog_name}</h2>
                   
                    <p dangerouslySetInnerHTML={{__html : blogDetailData.blog_desc}}></p>
                    
                    <div className="post-comment-section">
                        <div className="row">
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="news2-sidebar row">
                        
                        <div className="col-md-12">
                            <div className="widget">
                                <div className="widget-title">
                                    <h6>Recent Posts</h6>
                                </div>
                                <ul className="recent">
                                    {recentPostData.map((value)=>(
                                    <li>
                                        <div className="thum"> <img src={value.blog_image != null  ?recentPostImageUrl + value.blog_image:defaultImageUrl} alt=""/> </div> 
                                        <a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a>
                                    </li>))}
                                
                                </ul>
                            </div>
                        </div>
                       
                        <div className="col-md-12">
                            <div className="widget">
                                <div className="widget-title">
                                    <h6>Categories</h6>
                                </div>
                                <ul>
                                    {categoryData.map((value)=>(
                                    <li><a href={`/blogs/category/${value.category_slug}`}><i className="ti-angle-right"></i>{value.category_name}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<Footer/>
</>
)
}
export default BlogDetailPage