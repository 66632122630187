import axios from 'axios';
import constant from './constant';

const pageUrl = "page-data";
const settingsdataUrl = "setting-data";
const bloglistUrl =  "blog-list";
const blogdetailsUrl = "blog-details" ;
const featuredblogsUrl = "featured-blogs";
const testimonialsUrl = "testimonials";
const categorywisegallaryUrl = 'category-wise-gallery'
const gallerycategoryUrl = "gallery-category";
const menucategoryUrl = "menu-category"
const categorywisemenuUrl = "category-wise-menu";
const productcategoryUrl = "product-category";
const categorywiseproductUrl = "category-wise-product"
const newspartnerUrl =  "news-partner";
const enquiryprocessUrl =  "enquiry-process";
const recentblogUrl = "recent-blog";
const blogcategoriesUrl = "blog-categories";
const categorywiseblogsListUrl = "category-wise-blogs-List";
const newsletterurl = "newsletter";
const roomenquiryUrl = "room-enquiry";
const alljobsUrl = "all-jobs";
const careerprocessUrl = "career-process";
const contactusprocessUrl ="contact-us-process";
const sliderDataUrl = "slider-data";

const client = axios.create({
    baseURL: constant.API_URL,
  });
  export class ApiService {

    pagePostRequest(request) {
      return client.post(pageUrl, request)
    }

    settingsdataGetRequest() {
      return client.get(settingsdataUrl);
    }

    bloglistGetRequest() {
      return client.get(bloglistUrl);
    }
    blogdetailsPostRequest(request) {
      return client.post(blogdetailsUrl, request)
    }

    featuredblogsGetRequest() {
      return client.get(featuredblogsUrl)
    }
    testimonialsGetRequest() {
      return client.get(testimonialsUrl)
    }
    gallerycategoryGetRequest() {
      return client.get(gallerycategoryUrl)
    }
    categorywisegallaryPostRequest(request) {
      return client.post(categorywisegallaryUrl, request)
    }
    menucategoryGetRequest() {
      return client.get(menucategoryUrl)
    }

    categorywisemenuPostRequest(request) {
      return client.post(categorywisemenuUrl , request)
    }
    productcategoryGetRequest() {
      return client.get(productcategoryUrl)
    }
    categorywiseproductPostRequest(request) {
      return client.post(categorywiseproductUrl, request)
    }
    newspartnerUrlGetRequest() {
      return client.get(newspartnerUrl)
    }
    enquiryprocessPostRequest(request) {
      return client.post(enquiryprocessUrl, request)
    }
    recentblogGetRequest() {
      return client.get(recentblogUrl)
    }
    blogcategoriesGetRequest() {
      return client.get(blogcategoriesUrl)
    }
    categorywiseblogsListPostRequest(request) {
      return client.post(categorywiseblogsListUrl, request)
    }
    newsletterPostRequest(request) {
      return client.post(newsletterurl, request)
    }
    roomenquiryPostRequest(request) {
      return client.post(roomenquiryUrl, request)
    }
    careerprocessPostRequest(request) {
      return client.post(careerprocessUrl, request)
    }
    alljobsGetRequest() {
      return client.get(alljobsUrl)
    }
    contactusprocessPostRequest(request) {
      return client.post(contactusprocessUrl, request)
    }
    sliderDataGetRequest() {
      return client.get(sliderDataUrl);
    }
}