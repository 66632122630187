import React, { useEffect, useRef, useState } from 'react'
import Footer from "../../Components/Footer";
import { useParams } from "react-router-dom";
import PagesHeader from '../../Components/PagesHeader';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ApiService } from "../../Components/Services/apiservices";
import { Triangle } from 'react-loader-spinner'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
let apiServices = new ApiService();
function Kalagram() {  
  const didMountRef = useRef(true);
  const [pageData, setPageData] = useState({})
  const [productCategory, setProductCategory] = useState([])
  const [productData, setProductData] = useState([])
  const [productBasrUrl, setProductBaseUrl] = useState("")
  const [defaultImage, setDefaultImage] = useState("")
  const [categorySlug, setCategorySlug] = useState("")
  const [headerImage, setHeaderImage] = useState("")
  const [sppinerLoading, setSppinerLoading] = useState(false)
  const [pageContent, setPageContent] = useState('');

  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
        "page_url": "kalagram"
      }
      apiServices.pagePostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          if (res.data.pageData != null) {
            setPageData(res.data.pageData)
            setPageContent(res.data.pageData.page_content)

            setHeaderImage(res.data.page_header_url)
          }
        }
      })
      apiServices.productcategoryGetRequest().then(res => {
        if (res.data.status === "success") {
          setProductCategory(res.data.category)
          setProductData(res.data.productData)
          setProductBaseUrl(res.data.category_url)
          setDefaultImage(res.data.default_image_baseurl)
          setCategorySlug(res.data.category[0].category_slug)
        }
      })
    }
    didMountRef.current = false;
  });
  const categoryproduct = (cat_slug,index) => {
    setSppinerLoading(true)
    // setCategorySlug(cat_slug)
    const dataString = {
      "category_slug": cat_slug
    }
    apiServices.categorywiseproductPostRequest(dataString).then(res => {
      if (res.data.status === "success") {
        document.getElementById("tab-"+index).classList.add("active")
        setProductData(res.data.productData)
        setProductBaseUrl(res.data.product_url)
        setDefaultImage(res.data.default_image_baseurl)
        setSppinerLoading(false)
      }
    })
  }
  return (
    <>
      <PagesHeader />
      <div id="cappa-main">
        <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{ backgroundImage: `url(${headerImage + pageData.page_header_image})`,backgroundSize:'cover',backgroundPosition:'100%' }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center caption mt-90">
              {pageData.page_sub_header !=null ?<h5>{pageData.page_sub_header}</h5>:""}
              {pageData.page_header != null ? <h1>{pageData.page_header}</h1>:""}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {pageContent != null ?<div dangerouslySetInnerHTML={{ __html: pageContent}}></div>:""}
{/* 
      <section id="menu" className="restaurant-menu menu section-padding bg-blck pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb-30">
              <div className="section-subtitle"><span>Shop</span></div>
              <div className="section-title"><span>Our Collection</span></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row justify-content-center">
                <div className="tabs-icon col-md-10 text-center" style={{ marginBottom: "15px" }}>
                  <div className="owl-carousel owl-theme">
                                <div id="tab-1" className="active item">
                                    <h6>White Metal Bracelets</h6>
                                </div>
                                <div id="tab-2" className="item">
                                    <h6>Fancy Bangles</h6>
                                </div>
                                <div id="tab-3" className="item">
                                    <h6>Groovy Bracelets</h6>
                                </div>
                                <div id="tab-4" className="item">
                                    <h6>Ravishing Rings</h6>
                                </div>
                                <div id="tab-5" className="item">
                                    <h6>Voguish Neckpieces</h6>
                                </div>
                                <div id="tab-6" className="item">
                                    <h6>Whilte Metal Neckpieces</h6>
                                </div>
                                <div id="tab-7" className="item">
                                    <h6>Classy Jewels</h6>
                                </div>
                                <div id="tab-8" className="item">
                                    <h6>Bespoke Jewels</h6>
                                </div>
                                <div id="tab-9" className="item">
                                    <h6>The Quirky Treasures</h6>
                                </div>
                            </div>
                  <Swiper className='collectionswiper' navigation={true} modules={[Navigation]}
                  slidesPerView={1}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 50,
                    },
                  }}
                  >
                    {productCategory.map((value,index) => (
                      <SwiperSlide>
                        <div id={'tab-'+index} className={ value.category_slug  == categorySlug? "active item" : "item"} onClick={(e) => categoryproduct(value.category_slug,index)}>
                          <h6>{value.category_name}</h6>
                        </div>
                      </SwiperSlide>))}
                  
                  </Swiper>
                </div>
                {sppinerLoading === false ?
                  <div className="restaurant-menu-content col-md-12 mt-5">
                    <div id="tab-1-content" className="cont active">
                      <div className="row">
                        {productData.map((value) => (
                          <div className="col-lg-3 col-6" >
                            <div className="pricing-card" >
                              <img src={value.product_image != null ? productBasrUrl + value.product_image : "/assets/img/Chokhi_Dhani.png"} alt="" />
                              <div className="desc">
                                <div className="sub-name" style={{color: "white"}}>{value.product_name}</div>
                              
                                {value.product_selling_price != null ? <div className="amount">{"€" + value.product_selling_price}<span></span></div> : ""}
                                <div className="butn-dark"><a href="#"><span>Enquiry Now</span></a></div>
                              </div>
                            </div>
                          </div>))}
                        <div className="col-lg-3">
                                    <div className="pricing-card">
                  <img src="/assets/img/kgimg/whitemetal-2.jpg" alt="" />
                  <div className="desc">
                    <div className="sub-name">Persian Blue Stone</div>
                    <div className="name">Studded Bracelets</div>
                    <div className="amount">€3<span></span></div>
                    <div className="butn-dark me-3"><a href="#"><span>Buy Now</span></a></div>
                  </div>
                </div>
                                    </div>
                      </div>
                    </div>
                  </div>
                  : <Triangle
                    width="100%"
                    height="80px"
                    align-items="center"
                    justify-content="center"
                    color="#AA8453"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClassName="loader"
                    visible={true}
                  />}
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
    </>
  )
}

export default Kalagram