
import React, { useEffect,useRef, useState } from 'react'
import Footer from "../../Components/Footer";
import {useParams} from "react-router-dom";
import PagesHeader from '../../Components/PagesHeader';
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();

function ParkingVideo() {
    const didMountRef = useRef(true);
    const { slug } = useParams();
    const [pageData , setPageData] = useState({})
    const [headerImage , setHeaderImage] = useState("")
    const [pageContent, setPageContent] = useState('');

    useEffect(() => {
        if (didMountRef.current) { 
       const dataString = {
      "page_url" : "parking-video"
       }
       apiServices.pagePostRequest(dataString).then(res => {
        if(res.data.status === "success"){
            if(res.data.pageData != null){
          setPageData(res.data.pageData)
          setHeaderImage(res.data.page_header_url) 
          setPageContent(res.data.pageData.page_content)

            }
       }
    })
    }

  didMountRef.current = false;
  }); 
    return (
      <>
<PagesHeader/>

<div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{backgroundImage: `url(${headerImage + pageData.page_header_image})`,backgroundSize:'cover',backgroundPosition:'100%'}}>
<div className="container">
<div className="row">
<div className="col-md-12 text-center caption mt-90">
{pageData.page_sub_header != null  ?<h5>{pageData.page_sub_header}</h5>:""}
{/* <h5>Chokhi Dhani </h5> */}
{/* <h1>News</h1> */}
{pageData.page_header != null ?<h1>{pageData.page_header}</h1>:""}
</div>
</div>
</div>
</div>
{pageContent != null ?<div dangerouslySetInnerHTML={{ __html: pageContent}}></div>:""}
<Footer/>

</>
)
}

export default ParkingVideo