import React, { useEffect, useRef, useState } from 'react'
import Footer from "../../Components/Footer";
import { useParams } from "react-router-dom";
import PagesHeader from '../../Components/PagesHeader';
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();
function Gallery() {
  const didMountRef = useRef(true);
  const { slug } = useParams();
  const [pageData, setPageData] = useState({})
  const [gallaryCategory, setGallaryCategory] = useState([])
  const [gallaryImageUrl, setGallaryImageUrl] = useState("")
  const [headerImage, setHeaderImage] = useState("")

  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
        "page_url": "gallery"
      }
      apiServices.pagePostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          if (res.data.pageData != null) {
            setPageData(res.data.pageData)
            setHeaderImage(res.data.page_header_url)

          }
        }
      })
      apiServices.gallerycategoryGetRequest().then(res => {
        if (res.data.status == "success") {
          setGallaryCategory(res.data.category)
          setGallaryImageUrl(res.data.category_img_url)

        }
      })
    }

    didMountRef.current = false;
  }, []);
  return (
    <>
      <PagesHeader />

      <div id="cappa-main">
        <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{ backgroundImage: `url(${headerImage + pageData.page_header_image})`, backgroundSize: 'cover', backgroundPosition: '100%' }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center caption mt-90">
                <h5></h5>
                {pageData.page_header != null ? <h1>{pageData.page_header}</h1> : ""}
              </div>
            </div>
          </div>
        </div>
        <section className="rooms1 section-padding bg-blck-light" data-scroll-index="2">
          <div className="container">

            <div className="row">
              {gallaryCategory.map((value) => (
                <div className="col-md-4">
                  <div className="item">
                    <div className="position-re o-hidden"> <img src={value.category_image != null ? gallaryImageUrl + value.category_image : "/assets/img/defaultimage.png"} alt="" /> </div>
                    <div className="con">
                      <h5><a href={`/gallery/${value.category_slug}`}>{value.category_name}</a> </h5>
                      <div className="line"></div>
                      <div className="row facilities">

                        <div className="col col-md-12 text-end">
                          <div className="permalink"><a href={`/gallery/${value.category_slug}`}>View<i className="ti-arrow-right"></i></a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>))}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
}

export default Gallery